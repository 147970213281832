<script>
import {HorizontalBar} from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: HorizontalBar,
  props: ['data'],
  computed: {
    chartLabels() {
      return this.data.map(d => d.name)
    },
    chartData() {
      return this.data.map(d => parseInt(d.count))
    }
  },
  mounted() {
    this.addPlugin(ChartDataLabels)

    this.renderChart(
      {
        labels: this.chartLabels,
        datasets: [
          {
            label: 'Shoutouts sent',
            data: this.chartData,
            backgroundColor: 'rgba(84, 41, 129, 0.5)',
            borderColor: 'rgb(84, 41, 129)',
            borderWidth: 1
          }
        ]
      },
      {
        plugins: {
          datalabels: {
            // anchor: function({chart, dataIndex}) {
            //   const meta = chart.getDatasetMeta(0).data[dataIndex];
            //   const width = meta._view;

            //   console.log(width)

            //   if(width > 40) return 'center';
            //   return 'end';
            // },
            // align: function({chart, dataIndex}) {
            //   const meta = chart.getDatasetMeta(0).data[dataIndex];
            //   const width = meta._view.x - meta._view.base;

            //   if(width > 40) return 'center';
            //   return 'end';
            // },
            anchor: 'end',
            align: 'end',
            formatter: function(value, context) {
              return `${value}`;
            }
          }
        },
        layout: {
          padding: {
            right: 50
          }
        },
        aspectRatio: 1,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          xAxes: [{
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              precision: 1,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }]
        },
        events: false,
        tooltips: {
            enabled: false
        },
        hover: {
            animationDuration: 0
        }
      }
    )
  }
}
</script>